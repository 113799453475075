.rbc-toolbar button {
    color: #1CF086 !important;
    border: 1px solid #1CF086 !important;

}

.rbc-toolbar button:hover {
    background-color: #161d31 !important;
}

.rbc-today {
    background-color: #161d31 !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
    background-color: #161d31 !important;
}

.rbc-off-range-bg {
    background: #1f374d !important;
}

rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
    display: none !important;
}

.rbc-day-slot .rbc-event-content {
    position: relative;

}


.rbc-event,
.rbc-day-slot .rbc-background-event {
    background-color: transparent !important;
    border: 2px solid #04bcc4 !important;

}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
    border: none !important;
    padding: 0 !important;
}

.rbc-toolbar .rbc-toolbar-label {
    font-size: 10px;
    padding: 0 !important;
}

.rbc-toolbar button {
    padding: 0.375rem 0.6rem !important;
}

.rbc-day-slot .rbc-events-container {
    margin-right: 2px !important;
}

.rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    padding: 16px 4px;
    gap: 4px;
    box-sizing: border-box;
    justify-content: flex-start !important;
    align-items: center;
    margin-bottom: 0 !important;
    font-size: 14px !important;
}

.rbc-time-slot {
    font-size: 12px;
}

.rbc-time-header-gutter {
    width: 40px !important;
    min-width: 40px !important;
    max-width: 40px !important;
}

.rbc-timeslot-group {
    border-bottom: unset !important;
}

.rbc-day-slot .rbc-time-slot {
    border-top: 0.2px solid #dddddd3d !important;
}

.rbc-time-content>*+*>* {
    border-left: 1px solid #dddddd3d !important;
}